import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {ConfigurationStepEnum} from "configurations/shared/configuration_editor/models/configuration_step.enum";
import {IStepChange} from "configurations/shared/configuration_editor/models/stepChange.model";
import {ConfigurationCompletionState} from "./completion.model";
import {validateState} from "./completion.reducer";
import {
    complete,
    getAdditionDocuments,
    getOrderComplete,
    getProductionInstructionDocuments,
    initData
} from "./completion.thunks";
import {QuantityModel} from "../../../../../../models/quantity.model";
import {StepDescriptor} from "../../../models/step_descriptor.model";
import {setErrorInState} from "shared/networking/error_handling";

const initialState: ConfigurationCompletionState = {
    loadedData: {
        stepState: {
            currentStep: ConfigurationStepEnum.Completion,
            configurationId: null,
        },
        configuration: null,
        isNavigating: false,
    },
    actualData: {
        handleStepChange: false,
        shipmentDate: null,
        commission: null,
        amount: null,
        isScarTreatment: false,
        isLoading: true,
        isInitializing: false,
    },
    command: {
        ordercomplete: {
            status: "idle",
            canExecute: false
        },
        goBackToProductInstructions: {
            status: "idle",
            canExecute: false
        },
        releaseLock: {
            status: "idle",
            canExecute: false
        }
    },
    query: {
        get: {
            status: "idle",
            canExecute: false
        },
        init: {
            status: "idle",
            canExecute: false
        }
    }
};

export const configurationCompletionSlice = createSlice({
    name: "configuration/completion",
    initialState,
    reducers: {
        setCommission: (state, action: PayloadAction<string>) => {
            state.actualData.commission = action.payload;
            state.command.ordercomplete.canExecute = validateState(state);
        },
        setAmount: (state, action: PayloadAction<QuantityModel>) => {
            state.actualData.amount = {...action.payload, isSelected: true};
            state.command.ordercomplete.canExecute = validateState(state);
        },
        setShipmentDate: (state, action: PayloadAction<string>) => {
            state.actualData.shipmentDate = action.payload;
            state.command.ordercomplete.canExecute = validateState(state);
        },
        resetState: (state) => {
            state.actualData = initialState.actualData;
            state.loadedData = initialState.loadedData;
            state.command = initialState.command;
            state.query = initialState.query;
        },
        resetSetOrderComplete: (state) => {
            state.command.ordercomplete.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetGotoProductInstructions: (state) => {
            state.command.goBackToProductInstructions.status = "idle";
            state.loadedData.stepState.targetStep = null;
        },
        resetStepChange: (state) => {
            state.actualData.handleStepChange = false;
            state.loadedData.stepState.targetStep = null;
        },
        setIsNavigate: (state) => {
            state.loadedData.isNavigating = true;
        },
        changeStep: (state, action: PayloadAction<IStepChange>) => {
            state.loadedData.stepState.targetStep = action.payload.targetStep;
            state.actualData.handleStepChange = true;
        },
        goBack: (state, action: PayloadAction<StepDescriptor>) => {
            state.loadedData.stepState.targetStep = action.payload;
            state.actualData.handleStepChange = true;
        },
        initData: (state) => {
            state.actualData.isLoading = true;
        }
    }, extraReducers: (builder) => {
        builder
            // getOrderComplete
            .addCase(getOrderComplete.pending, (state) => {
                state.query.get.status = "pending";
                state.query.get.canExecute = false;
            })
            .addCase(getOrderComplete.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getOrderComplete.fulfilled, (state, action) => {
                state.query.get.status = "success";
                const data = action.payload.getData();
                state.loadedData.configuration = data;
                state.actualData.commission = data.commission;
                state.actualData.isScarTreatment = data.isScarTreatment;
                state.actualData.shipmentDate = data.displayShipmentDate.date;
                state.actualData.amount = data.displayQuantity.filter((e => e.isSelected))[0];
                state.command.ordercomplete.canExecute = validateState(state); // always allow completion if valid
            })

            // orderComplete
            .addCase(complete.pending, (state) => {
                state.command.ordercomplete.status = "pending";
                state.command.ordercomplete.canExecute = false;
                state.actualData.isLoading = true;
            })
            .addCase(complete.rejected, (state, action) => {
                setErrorInState(state.command.ordercomplete, action);
                state.command.ordercomplete.canExecute = validateState(state);
                state.actualData.isLoading = false;
            })
            .addCase(complete.fulfilled, (state) => {
                state.command.ordercomplete.status = "success";
                state.actualData.isLoading = true;
            })

            // getProdcutionInstructionDocuments

            // eslint-disable-next-line
            .addCase(getProductionInstructionDocuments.pending, (state) => {
            })
            .addCase(getProductionInstructionDocuments.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getProductionInstructionDocuments.fulfilled, (state, action) => {
                state.loadedData.configuration.productionInstructions = action.payload;
            })

            // getAdditionDocuments
            // eslint-disable-next-line
            .addCase(getAdditionDocuments.pending, (state) => {
            })
            .addCase(getAdditionDocuments.rejected, (state, action) => {
                setErrorInState(state.query.get, action);
            })
            .addCase(getAdditionDocuments.fulfilled, (state, action) => {
                state.loadedData.configuration.additions = action.payload;
            })

            // initData
            .addCase(initData.pending, (state) => {
                state.query.init.status = "success";
                state.actualData.isInitializing = true;
            })
            .addCase(initData.rejected, (state) => {
                state.actualData.isInitializing = false;
            })
            .addCase(initData.fulfilled, (state) => {
                state.query.init.status = "success";
                state.actualData.isInitializing = false;
                state.actualData.isLoading = false;
            })
        ;
    }
});

export const {
    setCommission,
    setAmount,
    setShipmentDate,
    resetState,
    resetGotoProductInstructions,
    resetSetOrderComplete,
    resetStepChange,
    setIsNavigate,
    changeStep,
    goBack
} = configurationCompletionSlice.actions;

export default configurationCompletionSlice.reducer;
