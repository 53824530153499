import {createAsyncThunk} from "@reduxjs/toolkit";
import {RootState} from "app/redux_store";
import ConfigurationService from "services/configuration.service";
import {handleResponseErrors} from "shared/networking/error_handling";
import {mustCallApi} from "./completion.reducer";
import {DocumentModel} from "../../../../../../models/documentModel";
import {ProductionInstructionModel} from "../../../../../../models/productionInstructionModel";
import {isPreviewableFiletype} from "../../../../configuration_documents/previewFiletypeFilter";
import {PositionModel} from "../../../../../../models/positionModel";
import {AdditionModel} from "../../../../../../models/additionModel";
import LockService from "services/lock.service";
import {resetState} from "./completion.slice";

interface CompleteArgs
{
    id: string,
    finalize: boolean,
}

export const complete = createAsyncThunk(
    "configuration/completion/complete",
    async (args: CompleteArgs, {getState, rejectWithValue}) => {
        const state = (getState() as RootState).configuration_completion;
        const mode = (getState() as RootState).configuration_mode.mode;
        if (!mustCallApi(state))
            return {}; // skip api call
        const response = await ConfigurationService.completion(
            args.id,
            state.actualData.amount.data,
            state.actualData.commission,
            state.actualData.shipmentDate,
            mode,
            args.finalize);

        return handleResponseErrors(response, "OrderConfiguration", rejectWithValue);
    }
);

export const getOrderComplete = createAsyncThunk(
    "configuration/completion/get",
    async (id: string, {getState, rejectWithValue}) => {
        const mode = (getState() as RootState).configuration_mode.mode;
        const response = await ConfigurationService.getCompletion(id, mode);
        return handleResponseErrors(response, "OrderConfiguration", rejectWithValue);
    }
);

export const getProductionInstructionDocuments = createAsyncThunk(
    "configuration/completion/getProductionInstructionDocuments",
    async (id: string, {getState}) => {
        const updatedInstructions = await Promise.all(
            (getState() as RootState).configuration_completion.loadedData.configuration.productionInstructions.map(async (instruction) => {
                const updatedDocuments = await Promise.all(
                    instruction.documents.map(async (doc) => {
                        const documentId = doc.documentId;
                        const tokenResponse = await ConfigurationService.getProductionInstructionDocumentToken(id, documentId);
                        const token = tokenResponse.getData().token;
                        const documentBlob = await ConfigurationService.getDocument(token);
                        const isPreviewable = isPreviewableFiletype(doc.name);
                        const document = new DocumentModel(doc.name, doc.documentId, documentBlob.getData(), isPreviewable);
                        return document;
                    })
                );

                return new ProductionInstructionModel(instruction.instruction, instruction.symbol, instruction.freeText, updatedDocuments);
            })
        );

        return updatedInstructions;
    }
);


export const getAdditionDocuments = createAsyncThunk(
    "configuration/completion/getAdditionDocuments",
    async (id: string, {getState}) => {
        const updatedAdditions = await Promise.all(
            (getState() as RootState).configuration_completion.loadedData.configuration.additions.map(
                async (instruction) => {
                    const updatedDocuments = await Promise.all(
                        instruction.positions.map(async (position) => {
                            const updatedDocs = await Promise.all(
                                position.documents.map(async (doc) => {
                                    const documentId = doc.documentId;
                                    const tokenResponse = await ConfigurationService.getAdditionDocumentToken(
                                        id,
                                        documentId
                                    );
                                    const token = tokenResponse.getData().token;
                                    const documentBlob = await ConfigurationService.getDocument(token);
                                    const isPreviewable = isPreviewableFiletype(doc.name);
                                    const document = new DocumentModel(
                                        doc.name,
                                        doc.documentId,
                                        documentBlob.getData(),
                                        isPreviewable
                                    );
                                    return document;
                                })
                            );

                            return new PositionModel(position.text, updatedDocs);
                        })
                    );

                    return new AdditionModel(instruction.text, updatedDocuments);
                }
            )
        );

        return updatedAdditions;
    }
);

export const releaseLock = createAsyncThunk(
    "configuration/releaselock",
    async (id: string) => {
        const response = await LockService.release(id);
        handleResponseErrors(response, "Configuration");
        return response;
    }
);

export const initData = createAsyncThunk(
    "configuration/initData",
    async (id: string, {dispatch}) => {
        await dispatch(resetState());
        const result = await dispatch(getOrderComplete(id));
        if (result.meta.requestStatus == "rejected")
        {
            if (result.meta.rejectedWithValue)                
                throw result.payload;         
            else
                throw new Error();
        }           

        await Promise.all([
            dispatch(getAdditionDocuments(id)),
            dispatch(getProductionInstructionDocuments(id))
        ]);
    }
);
